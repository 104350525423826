import React, { useEffect, useRef } from 'react'
import './chi.css'
export default function Cancer() {
    const aboutUsRef = useRef(null);
   
    useEffect(() => {
        
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
            <div className="image-section-chi first-section-cancer">
                <div class="carousel-overlay-img"></div>
                <div className="animate one">
        <span>C</span><span>A</span><span>N</span><span>C</span><span>E</span><span>R</span>  &nbsp;
        <span>P</span><span>A</span><span>T</span><span>I</span><span>E</span><span>N</span><span>T</span><span>S</span>  &nbsp;
        <span>T</span><span>R</span><span>E</span><span>A</span><span>T</span><span>M</span><span>E</span><span>N</span><span>T</span>  &nbsp;
      </div>                 </div>
            <div ref={aboutUsRef} className="content-container">

                <div className="left-content" style={{ 
                    backgroundImage: 'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbx7m6r9zDijMORy0Ugo2euL8JT8L4CRXwPw&s)', 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center', 
                    backgroundRepeat: 'no-repeat', 
                    opacity: 0.8, 
                    padding: '20px', 
                    color: 'black' ,
                    borderRadius:'20px'
                }}>

                <p>Cancer is a significant health concern in India, with a high burden of cases. The prevalence of cancer varies across different regions and demographic groups. Common types include breast, lung, cervical, and oral cancers. Risk factors include tobacco use, unhealthy diet, and genetic predisposition.</p>
                <p>The healthcare infrastructure for cancer diagnosis and treatment in India has been improving, but challenges like late-stage detection and limited access to advanced therapies persist. Various government and non-governmental initiatives aim to raise awareness, promote early detection, and enhance treatment facilities.</p>
                                 <p>Cancer registries track and analyze cancer data, aiding in understanding patterns and guiding public health strategies. The National Cancer Registry Program (NCRP) in India is actively involved in this effort.</p>
                                 <p>While progress is being made, addressing cancer in India requires a holistic approach, including prevention, early detection, and accessible and affordable treatment options. Public health campaigns and ongoing research play crucial roles in mitigating the impact of cancer on the population.</p>
                </div>

                <div className="right-content-chi   .chi">

                    <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/10.jpg" alt="Right side image" />
                </div>
            </div>
         
        </div>
    )
}

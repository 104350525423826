import React,{useEffect,useRef} from 'react'
import './gly.css'


export default function Gallery() {
    const aboutUsRef = useRef(null);
    
    useEffect(() => {
       
        window.scrollTo(0, 0);
      }, []);
      
  return (
    <div>
         <div>
          <div className="image-section-gly first-section-gly">
      <div class="carousel-overlay-img"></div>
      <div className="animate one">
        <span>G</span><span>A</span><span>L</span><span>L</span><span>A</span><span>R</span><span>Y</span>
      </div>            </div>
      <div className="grid-container-gly">
      
        <div className="grid-item-gly">
          <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/1.jpg" style={{height:250}} alt="Award 1" />
        </div>
        <div className="grid-item-gly">
          <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/2.jpg" style={{height:250}} alt="Award 2" />
        </div>
        <div className="grid-item-gly">
          <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/4.jpg" style={{height:250}} alt="Award 3" />
        </div>
        <div className="grid-item-gly">
          <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/5.jpg" style={{height:250}} alt="Award 4" />
        </div>
        <div className="grid-item-gly">
          <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/10.jpg" style={{height:250}} alt="Award 5" />
        </div>
        <div className="grid-item-gly">
          <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/11.jpg" style={{height:250}} alt="Award 6" />
        </div>
        <div className="grid-item-gly">
          <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/58.jpg" style={{height:250}} alt="Award 7" />
        </div>
        <div className="grid-item-gly">
          <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/51.jpg" style={{height:250}} alt="Award 7" />
        </div>
        <div className="grid-item-gly">
          <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/62.jpg" style={{height:250}} alt="Award 7" />
        </div>
        <div className="grid-item-gly">
          <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/61.jpg" style={{height:250}} alt="Award 7" />
        </div>
        <div className="grid-item-gly">
          <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/60.jpg" style={{height:250}} alt="Award 7" />
        </div>
        <div className="grid-item-gly">
          <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/57.jpg" style={{height:250}} alt="Award 7" />
        </div>
      
        </div>
    </div>
    </div>
  )
}

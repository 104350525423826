import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import 'jquery';
import 'bootstrap/dist/js/bootstrap.js';
import 'popper.js/dist/umd/popper';
import 'bootstrap/dist/css/bootstrap.css';
import './Navbar.css';

export default function Navbar() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);
  const [isTop, setIsTop] = useState(true);
  const lastScrollY = useRef(window.scrollY);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setIsTop(currentScrollY === 0);

    if (currentScrollY > lastScrollY.current) {
      setScrollDirection('down');
    } else {
      setScrollDirection('up');
    }

    lastScrollY.current = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigateTo = (path) => {
    setIsOpen(false);
    navigate(path);
  };

  return (
    <nav
      className={`navbar navbar-expand-md ${
        scrollDirection === 'down' ? 'hidden' : 'visible'
      } ${!isTop ? 'navbar-scrolled' : ''}`}
    >
      
      <div className="container-fluid">
        <ul className="navbar-nav ">
          <li className="nav-item none" onClick={() => navigateTo('/')}>
            <img
              src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/iwf-removebg-preview.png"
              alt="Organization Logo"
              style={{ width: '180px', height: '80px', borderRadius: 10 }}
            />
          </li>
          <li className="nav-item">
            <p className="nav-link" style={{ marginTop: 7 }} onClick={() => navigateTo('/')}>
              Home
            </p>
          </li>
        </ul>
        <button
          className="navbar-toggler text-white bg-info"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mynavbar"
          onClick={toggleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="mynavbar">
          <ul className="navbar-nav">
            <li className="nav-item">
              <p className="nav-link" onClick={() => navigateTo('/about-us')}>
                About Us
              </p>
            </li>
            <li className="nav-item nav-programs">
              <p className="nav-link">
              Initiatives
                <div className="programs-dropdown" style={{ color: 'black' }}>
                <div className="program-option" onClick={() => navigateTo('/UpcomingCampaign')}>
                Upcoming Campaign
                  </div>
                  <hr />
                  <div className="program-option" onClick={() => navigateTo('/child')}>
                    Child Education
                  </div>
                  <hr />
                  <div className="program-option" onClick={() => navigateTo('/cancer')}>
                    Cancer Patients Treatment
                  </div>
                  <hr />
                  <div className="program-option" onClick={() => navigateTo('/env')}>
                    Environmental Protection
                  </div>
                  <hr />
                  <div className="program-option" onClick={() => navigateTo('/women')}>
                    Women Empowerment
                  </div>
                  <hr />
                  <div className="program-option" onClick={() => navigateTo('/ration')}>
                    Ration kit to poor Families
                  </div>
                  <hr />
                  <div className="program-option" onClick={() => navigateTo('/animal')}>
                    Animal Protection
                  </div>
                </div>
              </p>
            </li>
            <li className="nav-item">
              <p className="nav-link" onClick={() => navigateTo('/gallery')}>
                Gallery
              </p>
            </li>
            <li className="nav-item">
              <p className="nav-link" onClick={() => navigateTo('/success')}>
              Milestone Moments
              </p>
            </li>
            <li className="nav-item">
              <p className="nav-link" onClick={() => navigateTo('/contact-us')}>
                Contact Us
              </p>
            </li>
            <li className="nav-item " style={{ zIndex: 10 }}>
              <p className="nav-link donate" onClick={() => navigateTo('/donate')} style={{ color: 'black' }}>
                Donate&#x1F496;
              </p>
            </li>
          </ul>
        </div>
      </div>
   
    </nav>
  );
}

import React, { useEffect, useState } from "react";

export default function UpcomingCampaign() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    body:{
        margin: "0px auto",
        padding: "20px",
        backgroundImage: "url('https://images.hdqwalls.com/wallpapers/early-morning-mountains-scenery-8n.jpg')",    },
    outerBox: {
      width: "95%",
      maxWidth: "1200px",
      margin: "120px auto",
      padding: "20px",
      backgroundColor:'white',
      borderRadius: "12px",
    },
    container: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      width: "100%",
      height: isMobile ? "1000px" : "90vh",
      backgroundColor:'white',
      borderRadius: "10px",
      overflow: "hidden",
      boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
      backgroundColor: "#fff",
      position: "relative",
      
      zIndex: 3,
    },
    imageContainer: {
      width: isMobile ? "100%" : "40%",
      height: isMobile ? "550px" : "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f5f5f5",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    contentContainer: {
      flex: 1,
      padding: "20px",
      overflowY: "auto",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "10px",
      color: "#333",
      textAlign: isMobile ? "center" : "left",
    },
    date: {
      fontSize: "16px",
      color: "#666",
      marginBottom: "15px",
    },
    scrollableContent: {
      height: "100%",
      paddingRight: "10px",
    },
  };

  return (
    <div style={styles.body}>
    <div style={styles.outerBox}>
      <div style={styles.container}>
        {/* Mobile: Show Date First */}
        {isMobile && <h2 style={styles.title}>Anti-Rabies Vaccination Drive</h2>}

        {isMobile && <p style={styles.date}>📅 Date: Jan 31, 2025 </p>}

        {/* Image Section */}
        <div style={styles.imageContainer}>
          <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/IMG_20250129_184103.jpg" alt="Vaccination Drive" style={styles.image} />
        </div>

        {/* Content Section */}
        <div style={styles.contentContainer}>
          {/* Desktop: Show Title & Date at the Top */}
          {!isMobile && <h2 style={styles.title}>Anti-Rabies Vaccination Drive</h2>}
          {!isMobile && <p style={styles.date}>📅 Date: Jan 31, 2025</p>}

          <div style={styles.scrollableContent}>
  <p style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "16px", lineHeight: "1.6" }}>
    The Indian Welfare Foundation is gearing up for a large-scale anti-rabies vaccination drive for stray dogs in Indira Nagar, Bangalore, with a target of vaccinating 350 dogs.
  </p>
  <p style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "16px" }}>
    This campaign is a continuation of the foundation’s commitment to animal welfare and public health.
  </p>

  <h3 style={{ fontFamily: "'Merriweather', serif", fontSize: "22px", fontWeight: "bold" }}>
    Key Objectives of the Campaign:
  </h3>

  <h4 style={{ fontFamily: "'Roboto Slab', serif", fontSize: "20px", fontWeight: "600" }}>
    1. Preventing Rabies Spread:
  </h4>
  <p style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "16px" }}>
    Rabies is a fatal disease that poses a serious risk to both animals and humans. By vaccinating stray dogs, the foundation aims to eliminate the risk of rabies transmission in the area.
  </p>

  <h4 style={{ fontFamily: "'Roboto Slab', serif", fontSize: "20px", fontWeight: "600" }}>
    2. Ensuring Stray Dog Welfare:
  </h4>
  <p style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "16px" }}>
    The campaign will protect the health of stray dogs, preventing them from contracting and spreading the virus.
  </p>
  <p style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "16px" }}>
    Vaccinated dogs will be marked or tagged to keep track of immunization status.
  </p>

  <h4 style={{ fontFamily: "'Roboto Slab', serif", fontSize: "20px", fontWeight: "600" }}>
    3. Public Safety & Awareness:
  </h4>
  <p style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "16px" }}>
    The initiative will help reduce fear and misunderstandings about stray dogs among the public.
  </p>
  <p style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "16px" }}>
    The team will also educate the community on responsible stray dog management and the importance of vaccination.
  </p>

  <h3 style={{ fontFamily: "'Merriweather', serif", fontSize: "22px", fontWeight: "bold" }}>
    Execution Plan:
  </h3>

  <h4 style={{ fontFamily: "'Roboto Slab', serif", fontSize: "20px", fontWeight: "600" }}>
    Survey & Identification:
  </h4>
  <p style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "16px" }}>
    Teams will conduct a pre-campaign survey to identify high-density stray dog areas in Indira Nagar.
  </p>

  <h4 style={{ fontFamily: "'Roboto Slab', serif", fontSize: "20px", fontWeight: "600" }}>
    Vaccination Drive:
  </h4>
  <p style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "16px" }}>
    Trained professionals, including veterinarians and volunteers, will administer anti-rabies vaccines to 350 stray dogs.
  </p>
  <p style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "16px" }}>
    Dogs will be marked to avoid duplicate vaccinations.
  </p>

  <h4 style={{ fontFamily: "'Roboto Slab', serif", fontSize: "20px", fontWeight: "600" }}>
    Post-Vaccination Monitoring:
  </h4>
  <p style={{ fontFamily: "'Open Sans', sans-serif", fontSize: "16px" }}>
    The team will keep track of vaccinated dogs and provide follow-up support if needed.
  </p>
</div>

        </div>
      </div>
    </div></div>
  );
}

import React,{useEffect,useRef,useState} from 'react'
import './ration.css'
import Slider from './StickyIcon';

export default function Ration() {
    const aboutUsRef = useRef(null);
   
    useEffect(() => {
        
        window.scrollTo(0, 0);
      }, []);
      const [isExpanded, setIsExpanded] = useState(false);

      const handleToggle = () => {
        setIsExpanded(!isExpanded);
      };
  return (
    <div>
         <div className="image-section-rat first-section-rat">
                <div class="carousel-overlay-img"></div>
                <div className="animate one">
        <span>R</span><span>A</span><span>T</span><span>I</span><span>O</span><span>N</span> &nbsp;
        <span>K</span><span>I</span><span>T</span> &nbsp;
        <span>T</span><span>O</span> &nbsp;
        <span>P</span><span>O</span><span>O</span><span>R</span> &nbsp;
        <span>F</span><span>A</span><span>M</span><span>I</span><span>L</span><span>I</span><span>E</span><span>S</span> &nbsp;
      </div>                 </div>
      <div className="container1">
      <div className="content1">
      <h1 className="report-title" style={{textAlign:'center'}}>Community Development Report: 5th Day Activity</h1>
      <p className="report-date"><strong>Date:</strong> 23rd June 2024</p>
      <p className="report-organizer"><strong>Organized by:</strong> Indian Welfare Foundation</p>
      <p className="report-location"><strong>Location:</strong> Irumbedu Village, Thiruporur near Chennai</p>
      <p className="report-description">
        Indian Welfare Foundation is dedicated to the upliftment of underprivileged communities. On the 23rd of June, 2024, we conducted a grocery and hygienic food distribution campaign for the Irular community in Irumbedu Village, Thiruporur near Chennai.
      </p>
        <div>
          <br />
          <span id="text1" style={{ display: isExpanded ? 'block' : 'none' }}>
          <p className="report-description">
        We distributed grocery kits to 74 families and provided nutritious, hygienic food for the community members. Additionally, we raised awareness about the importance of education, women's safety, and hygiene.
      </p>

      <h2 className="report-subtitle">About the Irular Community:</h2>
      <p className="report-description">
        The Irular community is an indigenous tribal group primarily residing in the Indian states of Tamil Nadu and Kerala. Traditionally, the Irulars have been involved in occupations such as snake and rat catching, and labor-intensive work in agriculture and brick kilns. Despite their significant contributions, the community faces considerable socio-economic challenges, including limited access to education, healthcare, and secure employment.
      </p>
      <p className="report-description">
        Irulars often experience marginalization and discrimination, which exacerbates their vulnerabilities. Many live in conditions of poverty, with inadequate housing and basic amenities. Efforts to uplift the Irular community include initiatives aimed at improving their living standards, providing educational opportunities, enhancing healthcare access, and protecting their rights.
      </p>
      <p className="report-description">
        By conducting this campaign, the Indian Welfare Foundation aims to support the Irular community in improving their quality of life and securing a better future.
      </p>
          <div className="grid-container-gly">
      
      <div className="grid-item-gly">
        <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/59.jpg" style={{height:250}} alt="Award 1" />
      </div>
      <div className="grid-item-gly">
        <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/51.jpg" style={{height:250}} alt="Award 2" />
      </div>
      <div className="grid-item-gly">
        <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/53.jpg" style={{height:250}} alt="Award 3" />
      </div>
      <div className="grid-item-gly">
        <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/54.jpg" style={{height:250}} alt="Award 4" />
      </div>
      <div className="grid-item-gly">
        <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/55.jpg" style={{height:250}} alt="Award 5" />
      </div>
      <div className="grid-item-gly">
        <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/58.jpg" style={{height:250}} alt="Award 6" />
      </div>
    
      </div>
      <img src='https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/50.jpg ' style={{height:550,width:"100%",borderRadius:'20px',paddingBottom:'10px'}}></img>

          </span>
        </div>
        <div className="btn-container">
          <button id="toggle" onClick={handleToggle}>
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
        </div>
      </div>
    </div>
            <div className='env'>
  <div className="overlay5"></div>
  <div className="text">
  <p><strong>Reducing hunger deaths involves a multifaceted approach:</strong></p>
  <p><strong>1.</strong> <strong>Food Distribution Programs:</strong> Implement and support programs that provide subsidized or free food to those in need, like your NGO’s ration kit distribution.</p>
  <p><strong>2. Economic Empowerment</strong>: Address the root causes of poverty by promoting education, skill development, and creating job opportunities to empower individuals economically.</p>
  <p><strong>3. Agricultural Development:</strong> Invest in sustainable agricultural practices, providing farmers with resources, technology, and knowledge to improve crop yields and income.</p>
  <p><strong>4. Government Policies:</strong> Advocate for and support policies that address food security, poverty alleviation, and social safety nets to ensure a comprehensive approach.</p>
  <p><strong>5. Healthcare Services:</strong> Improve access to healthcare, as malnutrition is often linked to health issues. Regular health check-ups and nutritional support can make a significant impact.</p>
  <p><strong>6. Education on Nutrition:</strong> Raise awareness about proper nutrition and its importance, especially among vulnerable communities. Education can empower people to make informed choices.</p>
  <p><strong>7. Community Engagement:</strong> Involve local communities in designing and implementing solutions. Community-driven initiatives tend to be more sustainable and effective.</p>
  <p><strong>8. Emergency Response:</strong> Develop and implement efficient systems for responding to food crises and emergencies promptly.</p>
    </div></div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
export default function Blog() {
  const navigate = useNavigate('')
  const { ref: cardsRef, inView: cardsInView } = useInView({
    triggerOnce: true,
    threshold: 0.1
  });
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    alert('Form submitted. Check console for details.');
    console.log(formData);
  };
  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);
  const blog1 = () => {
    navigate('/child')
  }
  const blog2 = () => {
    navigate('/ration')
  }
  const blog3 = () => {
    navigate('/env')
  }
  const blog4 = () => {
    navigate('/women')
  }
  const blog5 = () => {
    navigate('/cancer')
  }
  const blog6 = () => {
    navigate('/animal')
  }
  return (
    <div>

      <div ref={cardsRef} className={`row row-cols-1 row-cols-md-3 g-4 car ${cardsInView ? 'fade-in-cards' : ''}`}>
        <div class="col" >
          <div class="card h-100" onClick={blog1}>
            <img src="https://images.pexels.com/photos/3231359/pexels-photo-3231359.jpeg?auto=compress&cs=tinysrgb&w=600" class="card-img-top" alt="..."></img>
            <div class="card-body">
              <h5 class="card-title">Child Education</h5>
              <p class="card-text">The Indian Welfare Foundation (IWF) is fervently committed to transforming lives through its dedicated Child Education Initiative. Focused on breaking the chains of illiteracy and
                ,...</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100" onClick={blog5}>
            <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/10.jpg" class="card-img-top" alt="..."></img>
            <div class="card-body">
              <h5 class="card-title">Cancer Patients Treatment</h5>
              <p class="card-text">Cancer is a significant health concern in India, with a high burden of cases. The prevalence of cancer varies across different regions and demographic groups. Common types include breast,....</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100" onClick={blog3}>
            <img className="overlay1" src="https://images.pexels.com/photos/1250958/pexels-photo-1250958.jpeg?auto=compress&cs=tinysrgb&w=600" class="card-img-top" alt="..."></img>
            <div class="card-body">
              <h5 class="card-title">Environmental Protection</h5>
              <p class="card-text">Trees absorb carbon dioxide during photosynthesis and store carbon in their biomass and soils. Afforestation (planting trees in areas that were not forested before) and reforestation ....</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100" onClick={blog4}>
            <img src="https://images.pexels.com/photos/8887272/pexels-photo-8887272.jpeg?auto=compress&cs=tinysrgb&w=600" class="card-img-top" alt="..."></img>
            <div class="card-body">
              <h5 class="card-title">Women Empowerment</h5>
              <p class="card-text">Women empowerment in India is a complex and evolving narrative that reflects the dynamic interplay of cultural, social, and economic factors. ...</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100" onClick={blog2}>
            <img src="https://indianwelfarefoundation.s3.ap-south-1.amazonaws.com/11.jpg" class="card-img-top" alt="..."></img>
            <div class="card-body">
              <h5 class="card-title">Ration kit to poor Families</h5>
              <p class="card-text">Implement and support programs that provide subsidized or free food to those in need, like your NGO’s ration kit distribution..</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100" onClick={blog6}>
            <img src="https://images.pexels.com/photos/4147986/pexels-photo-4147986.jpeg?auto=compress&cs=tinysrgb&w=600" class="card-img-top" alt="..."></img>
            <div class="card-body">
              <h5 class="card-title">Animal Protection</h5>
              <p class="card-text">The legal and ethical protection of animals has a long standing in the United States. Anticruelty statutes were enacted in some colonies before the Revolutionary War....</p>
            </div>
          </div>
        </div>

      </div>
      <div className="content-containertest" style={{ marginBottom: '50px' }}>

        <div className="left-content1">

          <img src='https://mrvtechnology.com/wp-content/uploads/2023/09/20289106_6245917-removebg-preview.png' alt="Right side image" />


        </div>

        <div className="right-content" >

          <div >
            <form onSubmit={handleSubmit}>
              <center><p style={{ fontSize: '20px', fontWeight: 'bold' }}>Raise Your Thoughts</p></center>
              <div className='msg'>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                  <input
                    type="text"
                    name="firstName"
                    className='in'
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    style={{ flex: '1', marginRight: '10px', height: '40px', borderRadius: '5px', borderWidth: '1px' }}
                    required
                  />
                  <input
                    type="email"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className='in'
                    placeholder="Email"
                    style={{ flex: '1', marginLeft: '10px', height: '40px', borderRadius: '5px', borderWidth: '1px' }}
                    required
                  />
                </div></div>
              <div style={{ marginBottom: '20px' }}>
                <input
                  type="phonenumber"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Phone number"
                  style={{ width: '100%', height: '40px', borderRadius: '5px', borderWidth: '1px' }}
                  required
                />
              </div>
              <div style={{ marginBottom: '20px' }}>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Your Message"
                  style={{ width: '100%', height: '150px', borderRadius: '5px', borderWidth: '1px' }}
                  required
                />
              </div>
              <button type="submit" style={{ width: '30%', padding: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px' }}>
                Send
              </button>
            </form>
          </div>  </div>
      </div>
    </div>
  )
}
